import { color, fontSize } from '@gtg/styles/theme'
import media from '@gtg/utils/media-queries'
import React from 'react'
import styled from 'styled-components'
import NextSrc from '../../img/app/next.svg'
import PrevSrc from '../../img/app/prev.svg'

const StyledMonthSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${color.grey50};
  font-weight: 700;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  height: 56px;
  overflow: hidden;
  border: 1px solid ${color.grey200};
  ${media.sm`
    margin-bottom: 1rem;
  `}
  ${media.xs`
    font-size: ${fontSize.f2};
  `}
`

const PrevMonthButton = styled.button`
  touch-action: manipulation;
  background: unset;
  color: unset;
  margin: 0;
  padding: 0;
  border-radius: 0;
  :hover {
    background-color: ${color.grey200};
  }
  background-image: url(${PrevSrc});
  background-position: 50% center;
  background-repeat: no-repeat;
  min-width: 40px;
  min-height: 24px;
  height: 100%;
  flex-grow: 1;
  max-width: calc(100% / 7);
`

const NextMonthButton = styled(PrevMonthButton)`
  background-image: url(${NextSrc});
`

const Year = styled.div`
  margin: 0 1rem;
`

const MonthSelect = props => {
  return (
    <StyledMonthSelect>
      <PrevMonthButton onClick={() => props.onPrev()} />
      <Year>{props.currentMonthStr}</Year>
      <NextMonthButton onClick={() => props.onNext()} />
    </StyledMonthSelect>
  )
}

export default MonthSelect
