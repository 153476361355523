import React from 'react'
import { navigate } from 'gatsby'

const PrivateRoute = ({
  tanIsValid,
  userId,
  component: Component,
  location,
  ...rest
}) => {
  const isLoggedIn = tanIsValid
  if (!isLoggedIn && location.pathname !== `/app/kvsh/login/:userId`) {
    navigate('/app/kvsh/login/' + userId)
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
