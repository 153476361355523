import React from 'react'
import styled, { keyframes } from 'styled-components'

const OuterSpinnerCircle = styled.div`
  width: ${props => (props.size ? props.size + 'px' : '16px')};
  height: ${props => (props.size ? props.size + 'px' : '16px')};
  border: ${props => (props.size ? Math.max(props.size / 8, 2) + 'px' : '2px')}
    solid ${props => (props.color1 ? props.color1 : 'rgba(255, 255, 255, 0.1)')};
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
  top: 3px;
  left: -12px;
  right: 0;
  bottom: 0;
  display: inline-block;
  margin-right: 8px;
`
const Rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`

const InnerSpinnerCircle = styled.div`
  width: 100%;
  height: 100%;
  border-right: ${props =>
      props.size ? Math.max(props.size / 8, 2) + 'px' : '2px'}
    solid ${props => (props.color2 ? props.color2 : 'white')};
  border-left: ${props =>
      props.size ? Math.max(props.size / 8, 2) + 'px' : '2px'}
    solid transparent;
  border-top: ${props =>
      props.size ? Math.max(props.size / 8, 2) + 'px' : '2px'}
    solid transparent;
  border-bottom: ${props =>
      props.size ? Math.max(props.size / 8, 2) + 'px' : '2px'}
    solid transparent;
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
  top: ${props =>
    props.size ? '-' + Math.max(props.size / 8, 2) + 'px' : '-2px'};
  left: ${props =>
    props.size ? '-' + Math.max(props.size / 8, 2) + 'px' : '-2px'};
  right: 0;
  bottom: 0;
  animation: ${Rotate} 1.5s linear infinite;
`

const SubmitSpinner = props => (
  <OuterSpinnerCircle
    className={props.className}
    color1={props.color1}
    size={props.size}
  >
    <InnerSpinnerCircle color2={props.color2} size={props.size} />
  </OuterSpinnerCircle>
)

export default SubmitSpinner
