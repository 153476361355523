import { color, fontSize } from '../../../styles/theme'
import styled from 'styled-components'

export const VerboseShiftInfos = styled.pre`
  font-size: ${fontSize.f1};
  color: ${color.grey700};
  background: ${color.grey100};
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid ${color.grey200};
`
