import { color } from '@gtg/styles/theme'
import React from 'react'
import Icon from './icons'

export function displayBookingState(bookingState) {
  const displayState = {}
  const iconSize = 22
  switch (bookingState) {
    case 1:
      // status "requested""
      displayState.message = 'Angefragt'
      displayState.color = color.lightBlue800
      displayState.backgroundColor = color.lightBlue50
      displayState.borderColor = color.lightBlue200
      displayState.statusColor = color.lightBlue200
      displayState.statusBorderColor = color.lightBlue300
      displayState.icon = (
        <Icon glyph="requested" color={color.lightBlue800} size={iconSize} />
      )
      break
    case 3:
      // status "fixed" - but should be displayed as status requested to doctors
      displayState.message = 'Angefragt'
      displayState.color = color.lightBlue800
      displayState.backgroundColor = color.lightBlue50
      displayState.borderColor = color.lightBlue200
      displayState.statusColor = color.lightBlue200
      displayState.statusBorderColor = color.lightBlue300
      displayState.icon = (
        <Icon glyph="requested" color={color.lightBlue800} size={iconSize} />
      )
      break
    case 4:
      // status "declined"
      displayState.message = 'Dienst nicht zugeteilt'
      displayState.color = color.red800
      displayState.backgroundColor = color.grey50
      displayState.borderColor = color.grey200
      displayState.statusColor = color.grey200
      displayState.statusBorderColor = color.grey300
      displayState.icon = (
        <Icon glyph="deleted" color={color.red800} size={iconSize} />
      )
      break
    case 5:
      // status "booked"
      displayState.message = 'Gebucht'
      displayState.color = color.green800
      displayState.backgroundColor = color.green50
      displayState.borderColor = color.green200
      displayState.statusColor = color.green200
      displayState.statusBorderColor = color.green300
      displayState.icon = (
        <Icon glyph="booked" color={color.green800} size={iconSize} />
      )
      break
    case 6:
      // status "standby"
      displayState.message = 'Bereitschaft'
      displayState.color = color.cyan800
      displayState.backgroundColor = color.cyan50
      displayState.borderColor = color.cyan200
      displayState.statusColor = color.cyan200
      displayState.statusBorderColor = color.cyan300
      displayState.icon = (
        <Icon glyph="standby" color={color.cyan800} size={iconSize} />
      )
      break
    case 7:
      // status "Looking for a substitute"
      displayState.message = 'Gebucht - Vertretung wird gesucht'
      displayState.color = color.green800
      displayState.backgroundColor = color.green50
      displayState.borderColor = color.green200
      displayState.statusColor = color.green200
      displayState.statusBorderColor = color.green300
      displayState.icon = (
        <Icon glyph="booked" color={color.green800} size={iconSize} />
      )
      break
    case 8:
      // status "no vaccine available"
      displayState.message = 'Kein Impfstoff verfügbar'
      displayState.color = color.yellow800
      displayState.backgroundColor = color.yellow50
      displayState.borderColor = color.yellow200
      displayState.statusColor = color.yellow200
      displayState.statusBorderColor = color.yellow300
      displayState.icon = (
        <Icon glyph="deleted" color={color.yellow800} size={iconSize} />
      )
      break
    case 10:
      // status "request canceled"
      displayState.message = 'Anfrage storniert'
      displayState.color = color.red800
      displayState.backgroundColor = color.grey50
      displayState.borderColor = color.grey200
      displayState.statusColor = 'unset'
      displayState.statusBorderColor = color.grey300
      displayState.icon = (
        <Icon glyph="deleted" color={color.red800} size={iconSize} />
      )
      break
    case 99:
      // status "fully booked" - locally defined, not provided by API
      displayState.message = 'Nicht mehr verfügbar'
      displayState.color = color.grey800
      displayState.backgroundColor = color.grey50
      displayState.borderColor = color.grey200
      displayState.statusColor = color.grey200
      displayState.statusBorderColor = color.grey300
      displayState.icon = (
        <Icon glyph="deleted" color={color.grey800} size={iconSize} />
      )
      break
    case 100:
      // status "shift open" - locally defined, not provided by API
      displayState.statusColor = 'unset'
      displayState.statusBorderColor = color.grey300
      break
    case 101:
      // unknown status - locally defined, not provided by API
      displayState.statusColor = color.yellow200
      displayState.statusBorderColor = color.yellow300
      break
    default:
      // unknown status
      displayState.message = 'Unbekannter Status'
      displayState.color = color.yellow800
      displayState.backgroundColor = color.yellow50
      displayState.borderColor = color.yellow200
      displayState.statusColor = color.yellow200
      displayState.statusBorderColor = color.yellow300
      displayState.icon = (
        <Icon glyph="unknown" color={color.yellow800} size={iconSize} />
      )
      break
  }
  return displayState
}
