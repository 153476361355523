import { color, fontSize } from '@gtg/styles/theme'
import media from '@gtg/utils/media-queries'
import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'
import { displayBookingState } from '../displayBookingState'
import { RequestButton } from './requestButton'
import { CancelButton } from './cancelButton'
import { CancelLookForSubstituteButton } from './cancelLookForSubstituteButton'
import { FindSubstituteButton } from './findSubstituteButton'
import { Shift } from './shift'
import { BookingState } from './bookingState'
import { VerboseShiftInfos } from './verboseShiftInfos'

const Date = styled.div`
  font-weight: 700;
`
const NameShiftPlan = styled.div``

const TimeShiftPlan = styled.div``

const ShiftInfoLine1 = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  border-bottom: 1px solid ${color.grey300};
  margin-bottom: 1rem;
  font-size: ${fontSize.f3};
  ${media.xs`
    font-size: ${fontSize.f2};
  `}
`

const ShiftInfoLine2 = styled.div`
  margin-bottom: 1rem;
  font-size: ${fontSize.f3};
  ${media.xs`
    font-size: ${fontSize.f2};
  `}
`

const Time = styled.div`
  margin-right: 1rem;
`

const Booked = styled.span`
  font-weight: normal;
  color: ${color.grey400};
  margin-right: 0.5rem;
`

const StyledSeparator = styled.div`
  margin: 0 0.5rem;
  display: inline;
  font-weight: normal;
  color: ${color.grey400};
`

const FreeVacancy = styled.span`
  color: ${props => (props.fullyBooked ? color.red500 : color.green600)};
  font-weight: 900;
`

const Capacity = styled.div``

const RequestButtonWrapper = styled.div`
  margin-top: 1rem;
`

const EmptyText = styled.div`
  text-align: center;
  color: ${color.grey400};
`

const DateTimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${color.grey300};
  padding-bottom: 4px;
  margin-bottom: 1rem;
`

const ShiftInfo = styled.div`
  margin-bottom: 1rem;
`

const DeleteBooking = styled.div``

const CancellingBlocked = styled.div`
  height: 38px;
  padding: 0 0.5rem;
  width: 100%;
  margin-bottom: 0;
  font-size: ${fontSize.f2};
  color: ${color.grey400};
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Spacer = styled.div`
  width: 0.25rem;
`

const Separator = () => {
  return <StyledSeparator>|</StyledSeparator>
}

const AvailableShifts = props => {
  const verboseShiftInfos = false
  return (
    <>
      {props.shifts && !props.shiftsAreLoading && props.shifts.length === 0 && (
        <EmptyText>Keine Schichten verfügbar</EmptyText>
      )}
      {props.shifts &&
        props.shifts.map(
          (shift, i) =>
            // If shift is special shift, only show it if its status is not requested and not fixed
            shift.type &&
            (shift.type !== 2 ||
              (shift.type === 2 &&
                shift.booking &&
                shift.booking.buchungstatus &&
                shift.booking.buchungstatus !== 3 &&
                shift.booking.buchungstatus !== 1)) && (
              <Shift key={'shift' + shift.date + shift.id + i}>
                {/* Show different information wether user is on shift plan tab or on request shifts tab */}
                {!props.shiftPlan && (
                  <>
                    <ShiftInfoLine1>
                      <Time>
                        {moment(shift.startzeitdatum).format('HH:mm')} -{' '}
                        {moment(shift.endzeitdatum).format('HH:mm')}{' '}
                      </Time>
                      <Capacity>
                        {/* If shift is special shift or there is a booking without origin id (looking for substitute) do not show vacancy numbers */}
                        {shift.type === 2 ||
                        (shift.booking &&
                          shift.booking.originbookingid === null &&
                          shift.booking.mindirectbooking !== null) ? (
                          ''
                        ) : (
                          <>
                            <Booked>Verfügbar:</Booked>{' '}
                            <FreeVacancy
                              fullyBooked={
                                shift.capacity &&
                                shift.capacity.capacity <=
                                  shift.capacity.bookedcapacity
                              }
                            >
                              {shift.capacity &&
                                shift.capacity.capacity -
                                  shift.capacity.bookedcapacity}
                              &nbsp;/&nbsp;
                              {shift.capacity && shift.capacity.capacity}
                            </FreeVacancy>
                          </>
                        )}
                      </Capacity>
                    </ShiftInfoLine1>
                    <ShiftInfoLine2>
                      {verboseShiftInfos && (
                        <>
                          <VerboseShiftInfos>
                            {JSON.stringify(shift, null, 2)}
                          </VerboseShiftInfos>
                        </>
                      )}
                      {shift.name}
                      {shift.code ? (
                        <>
                          <Separator /> {shift.code}
                        </>
                      ) : (
                        ''
                      )}
                    </ShiftInfoLine2>
                  </>
                )}

                {props.shiftPlan && (
                  <>
                    <ShiftInfo>
                      <DateTimeWrapper>
                        <Date>{moment(shift.date).format('D. MMMM')}</Date>{' '}
                        <TimeShiftPlan>
                          {moment(shift.startzeitdatum).format('HH:mm')} -{' '}
                          {moment(shift.endzeitdatum).format('HH:mm')}
                        </TimeShiftPlan>
                      </DateTimeWrapper>
                      <NameShiftPlan>
                        {verboseShiftInfos && (
                          <>
                            <VerboseShiftInfos>
                              {JSON.stringify(shift, null, 2)}
                            </VerboseShiftInfos>
                          </>
                        )}
                        {shift.name}
                        {shift.code ? (
                          <>
                            <Separator /> {shift.code}
                          </>
                        ) : (
                          ''
                        )}
                      </NameShiftPlan>
                    </ShiftInfo>
                  </>
                )}

                {/* Show booking state - when there is no booking state show request button instead*/}
                <>
                  {shift.booking && shift.booking.buchungstatus ? (
                    <>
                      <BookingState
                        color={
                          displayBookingState(shift.booking.buchungstatus).color
                        }
                        backgroundColor={
                          displayBookingState(shift.booking.buchungstatus)
                            .backgroundColor
                        }
                        borderColor={
                          displayBookingState(shift.booking.buchungstatus)
                            .borderColor
                        }
                      >
                        {displayBookingState(shift.booking.buchungstatus).icon}
                        <Spacer />
                        {
                          displayBookingState(shift.booking.buchungstatus)
                            .message
                        }
                      </BookingState>
                      {shift.booking && shift.booking.buchungstatus === 1 && (
                        <DeleteBooking>
                          <CancelButton
                            selectedVaccCenterId={props.selectedVaccCenterId}
                            shift={shift}
                            accessToken={props.accessToken}
                            fetchShifts={props.fetchShifts}
                          >
                            Anfrage stornieren
                          </CancelButton>
                        </DeleteBooking>
                      )}
                      {
                        /* Shift already booked and show possibility to look for a substitute*/
                        shift.booking && shift.booking.buchungstatus === 5 && (
                          <DeleteBooking>
                            <FindSubstituteButton
                              selectedVaccCenterId={props.selectedVaccCenterId}
                              shift={shift}
                              accessToken={props.accessToken}
                              fetchShifts={props.fetchShifts}
                            >
                              Vertretung finden
                            </FindSubstituteButton>
                          </DeleteBooking>
                        )
                      }
                      {
                        /* Shift booked looking for a substitute, option to cancel*/
                        shift.booking && shift.booking.buchungstatus === 7 && (
                          <DeleteBooking>
                            <CancelLookForSubstituteButton
                              selectedVaccCenterId={props.selectedVaccCenterId}
                              shift={shift}
                              accessToken={props.accessToken}
                              fetchShifts={props.fetchShifts}
                            >
                              Vertretungssuche abbrechen
                            </CancelLookForSubstituteButton>
                          </DeleteBooking>
                        )
                      }
                      {shift.booking && shift.booking.buchungstatus === 3 && (
                        <CancellingBlocked>
                          Stornieren nicht mehr möglich.
                        </CancellingBlocked>
                      )}

                      {/* When the booking state is "deleted" only show request button when the shift is not fully booked */}
                      {shift.booking &&
                        shift.booking.buchungstatus === 10 &&
                        !props.shiftPlan &&
                        shift.capacity &&
                        !(
                          shift.capacity.capacity <=
                          shift.capacity.bookedcapacity
                        ) && (
                          <RequestButtonWrapper>
                            <RequestButton
                              selectedVaccCenterId={props.selectedVaccCenterId}
                              shift={shift}
                              accessToken={props.accessToken}
                              fetchShifts={props.fetchShifts}
                            >
                              Erneut anfragen
                            </RequestButton>
                          </RequestButtonWrapper>
                        )}
                    </>
                  ) : // If shift is full, hide booking button and show "fully booked" booking state instead - not provided by API.
                  shift.capacity &&
                    shift.capacity.capacity <= shift.capacity.bookedcapacity &&
                    (shift.booking === null ||
                      shift.booking?.mindirectbooking === null) ? (
                    <BookingState
                      color={displayBookingState(99).color}
                      backgroundColor={displayBookingState(99).backgroundColor}
                      borderColor={displayBookingState(99).borderColor}
                    >
                      {displayBookingState(99).icon}
                      <Spacer />
                      {displayBookingState(99).message}
                    </BookingState>
                  ) : shift.booking != null ||
                    shift.booking?.mindirectbooking != null ? (
                    <RequestButton
                      selectedVaccCenterId={props.selectedVaccCenterId}
                      shift={shift}
                      fetchShifts={props.fetchShifts}
                      accessToken={props.accessToken}
                    >
                      SOFORT BUCHEN
                    </RequestButton>
                  ) : (
                    <RequestButton
                      selectedVaccCenterId={props.selectedVaccCenterId}
                      shift={shift}
                      fetchShifts={props.fetchShifts}
                      accessToken={props.accessToken}
                    >
                      Anfragen
                    </RequestButton>
                  )}
                  {
                    /* Identify new status looking for substitute*/
                    shift.booking &&
                      shift.booking.mindirectbooking !== null &&
                      shift.booking.buchungstatus !== 7 &&
                      shift.booking.buchungstatus !== 5 && (
                        <CancellingBlocked>
                          Kurzfristige Vertretung gesucht, daher SOFORT buchbar
                        </CancellingBlocked>
                      )
                  }
                </>
              </Shift>
            )
        )}
    </>
  )
}

export default AvailableShifts
