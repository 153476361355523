import { Router } from '@reach/router'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import GlobalWrapper from '../components/globalWrapper'

// kvsh
import KVSHPrivateRoute from '../components/kvsh/privateRoute'
import KVSHSchedule from '../components/kvsh/schedule'
import KVSHTan from '../components/kvsh/tan'

const App = () => {
  const [tanIsValid, setTanIsValid] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  return (
    <GlobalWrapper>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Router basepath="/app">
        <KVSHTan
          path="/kvsh/login/:userId"
          tanIsValid={tanIsValid}
          setTanIsValid={setTanIsValid}
          setAccessToken={setAccessToken}
        />
        <KVSHPrivateRoute
          path="/kvsh/schedule/:userId"
          component={KVSHSchedule}
          tanIsValid={tanIsValid}
          accessToken={accessToken}
        />
      </Router>
    </GlobalWrapper>
  )
}

export default App
