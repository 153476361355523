import React, { useState } from 'react'
import { endpoint } from '../auth'
import { ShadowButton } from './shadowButton'
import { StyledSpinner } from './styledSpinner'
import { ErrorMessage } from './errorMessage'
import { color } from '../../../styles/theme'

export const FindSubstituteButton = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  async function substituteRequest(shift) {
    if (!isLoading) {
        setIsLoading(true)
        await fetch(
          `${endpoint}/shift/booking/${shift.booking.originbookingid}/substitute/`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${props.accessToken}`,
            },
          }
        )
          .then(response => {
            if (response.status === 200) {
              return response.json()
            } else {
              throw new Error('Vertretungssuche fehlgeschlagen')
            }
          })
          .then(json => {
              props.fetchShifts(props.selectedVaccCenterId)
              if(json.buchungstatus === 5) {
                  throw new Error('Vertretungssuche 24h vor der Schicht nicht mehr möglich')
              }
              setIsLoading(false)
          })
          .catch(error => {
            setErrorMessage(error.message)
            setIsLoading(false)
          })
    }
  }
  return (
    <>
      <ShadowButton onClick={() => substituteRequest(props.shift)}>
        {isLoading && (
          <StyledSpinner color1={color.grey100} color2={color.grey300} />
        )}
        {!isLoading && props.children}
      </ShadowButton>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
}
