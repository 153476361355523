import styled from 'styled-components'
import { color } from '../../../styles/theme'
import media from 'gatsby-theme-generic/src/utils/media-queries'

export const Shift = styled.div`
  box-shadow: ${color.grey300} 0px 0px 0px 1px, ${color.grey300} 0px 2px 4px;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  ${media.sm`
    margin-bottom: 1rem;
    padding: 0.75rem;
  `}
  ${media.xs`
    padding: 0.5rem;
  `}
`
