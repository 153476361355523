const env =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

export const endpoint =
  env === 'production'
    ? 'https://kvsh.lifetime.eu'
    : 'https://kvsh-staging.lifetime.eu'

export function auth(userId, tan) {
  return fetch(endpoint + '/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({
      hash: userId,
      pin: tan,
    }),
  })
    .then(response => {
      if (response.status === 102) {
        throw new Error('Dienst zur Zeit nicht verfügbar')
      } else if (response.status === 201) {
        throw new Error('Dienst zur Zeit nicht verfügbar')
      } else if (response.status === 200) {
        return response.json()
      } else if (response.status === 400) {
        throw new Error('Ungültige TAN')
      } else if (response.status === 401) {
        throw new Error('Ungültige TAN')
      } else {
        throw new Error('Dienst zur Zeit nicht verfügbar')
      }
    })
    .then(response => {
      return responseObj(true, response.access_token, '')
    })
    .catch(error => {
      return responseObj(false, '', error.message)
    })
}

function responseObj(isValid, accessToken, errorMessage) {
  return { isValid, accessToken, errorMessage }
}
