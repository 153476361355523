import React, { useState } from 'react'
import { endpoint } from '../auth'
import styled from 'styled-components'
import { fontSize } from '../../../styles/theme'
import moment from 'moment-timezone'
import { StyledSpinner } from './styledSpinner'
import { ErrorMessage } from './errorMessage'

export const Button = styled.button`
  height: 38px;
  padding: 0 0.5rem;
  width: 100%;
  font-size: ${fontSize.f2};
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RequestButton = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  async function requestShift(shift) {
    setIsLoading(true)
    if (shift?.booking?.mindirectbooking && shift.booking.buchungstatus !== 7) {
      await directBooking(shift, props)
    } else {
      await normalRequest(shift, props)
    }
  }

  async function directBooking(shift, props) {
    await fetch(
      `${endpoint}/shift/booking/${shift.booking.mindirectbooking}/direct_booking`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      }
    )
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 201) {
          return response.json()
        } else {
          throw new Error('Anfrage fehlgeschlagen')
        }
      })
      .then(_ => {
        props.fetchShifts(props.selectedVaccCenterId)
      })
      .catch(error => {
        setErrorMessage(error.message)
        setIsLoading(false)
      })
  }

  async function normalRequest(shift, props) {
    const shiftDate = moment(shift.date).format('YYYY-MM-DD')
    await fetch(
      `${endpoint}/shift/${props.selectedVaccCenterId}/${shift.id}/${shiftDate}/book/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      }
    )
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 201) {
          return response.json()
        } else {
          throw new Error('Anfrage fehlgeschlagen')
        }
      })
      .then(_ => {
        props.fetchShifts(props.selectedVaccCenterId)
      })
      .catch(error => {
        setErrorMessage(error.message)
        setIsLoading(false)
      })
  }

  return (
    <>
      <Button onClick={() => requestShift(props.shift)}>
        {isLoading && <StyledSpinner />}
        {!isLoading && props.children}
      </Button>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
}
