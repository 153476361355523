import React from 'react'
import styled from 'styled-components'

export const InlineSvg = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: inherit;
  fill: currentColor;
`

export const SvgWrapper = styled.div`
  display: inline-block;
  flex: 0 0 ${props => (props.size ? `${props.size}px` : '32px')};
  width: ${props => (props.size ? `${props.size}px` : '32px')};
  height: ${props => (props.size ? `${props.size}px` : '32px')};
  min-width: ${props => (props.size ? `${props.size}px` : '32px')};
  min-height: ${props => (props.size ? `${props.size}px` : '32px')};
  position: relative;
  color: inherit;
`

export const Glyph = ({ glyph, color }) => {
  switch (glyph) {
    case 'requested':
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M16 6H8C8 6 8 9.5 10 12C8 14.5 8 18 8 18H16C16 18 16 14.5 14 12C16 9.5 16 6 16 6Z"
            stroke={color}
            strokeWidth="1.5"
          />
          <path
            d="M12 15L15.5 17.5C15.5 17.5 9.5 17.5 8.5 17.5L12 15Z"
            fill={color}
          />
        </g>
      )
    case 'deleted':
      return (
        <g fill="none" fillRule="evenodd">
          <path d="M7 7L17 17" stroke={color} strokeWidth="1.5" />
          <path d="M17 7L7 17" stroke={color} strokeWidth="1.5" />
        </g>
      )
    case 'standby':
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M8.5 12.9L11.3025 15L15.5 9"
            stroke={color}
            strokeWidth="1.75"
          />
          <path
            d="M6.06031 5C2.77991 8.86599 2.77991 15.134 6.06031 19M17.9397 19C21.2201 15.134 21.2201 8.86599 17.9397 5"
            stroke={color}
            strokeWidth="1.5"
          />
        </g>
      )
    case 'fixed':
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M8.5 12.9L11.3025 15L15.5 9"
            stroke={color}
            strokeWidth="1.75"
          />
          <path
            d="M6.06031 5C2.77991 8.86599 2.77991 15.134 6.06031 19M17.9397 19C21.2201 15.134 21.2201 8.86599 17.9397 5"
            stroke={color}
            strokeWidth="1.5"
          />
        </g>
      )
    case 'booked':
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M6.5 13.5L10.7037 17L17 7"
            stroke={color}
            strokeWidth="1.75"
          />
        </g>
      )
    case 'unknown':
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M12.5241 14.846H10.9401C10.9281 14.702 10.9221 14.492 10.9221 14.216C10.9221 13.016 11.4201 12.08 12.4161 11.408L13.2081 10.868C13.8441 10.436 14.1621 9.84802 14.1621 9.10402C14.1621 8.52802 13.9761 8.04202 13.6041 7.64602C13.2321 7.25002 12.7041 7.05202 12.0201 7.05202C11.2881 7.05202 10.7301 7.28002 10.3461 7.73602C9.96211 8.19202 9.77011 8.73202 9.77011 9.35602C9.77011 9.71602 9.81211 10.01 9.89611 10.238L8.15011 10.022C8.07811 9.77002 8.04211 9.48202 8.04211 9.15802C8.04211 8.57002 8.17411 8.00602 8.43811 7.46602C8.70211 6.92602 9.14611 6.45802 9.77011 6.06202C10.3941 5.66602 11.1441 5.46802 12.0201 5.46802C13.2201 5.46802 14.1741 5.82802 14.8821 6.54802C15.6021 7.26802 15.9621 8.11402 15.9621 9.08602C15.9621 10.346 15.3501 11.39 14.1261 12.218L13.2981 12.776C12.7701 13.136 12.5061 13.712 12.5061 14.504C12.5061 14.612 12.5121 14.726 12.5241 14.846ZM10.8861 18.248C10.6581 18.02 10.5441 17.744 10.5441 17.42C10.5441 17.096 10.6581 16.82 10.8861 16.592C11.1141 16.352 11.3901 16.232 11.7141 16.232C12.0381 16.232 12.3141 16.352 12.5421 16.592C12.7821 16.82 12.9021 17.096 12.9021 17.42C12.9021 17.744 12.7821 18.02 12.5421 18.248C12.3141 18.476 12.0381 18.59 11.7141 18.59C11.3901 18.59 11.1141 18.476 10.8861 18.248Z"
            fill={color}
          />
        </g>
      )
    default:
  }
}

class Icon extends React.Component {
  render() {
    const { size, color, glyph } = this.props

    return (
      <SvgWrapper size={size || 24} className={'icon'}>
        <InlineSvg
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="1.414"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title"
          viewBox="0 0 24 24"
          preserveAspectRatio="xMidYMid meet"
          fit
          id={glyph}
        >
          <title id="title">{glyph}</title>
          <Glyph glyph={glyph} fill={color} color={color} />
        </InlineSvg>
      </SvgWrapper>
    )
  }
}

export default Icon
