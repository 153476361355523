import { fontSize } from '../../../styles/theme'
import media from 'gatsby-theme-generic/src/utils/media-queries'
import styled from 'styled-components'

export const BookingState = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: ${props => props.color};
  background: ${props => props.backgroundColor};
  border: 1px solid ${props => props.borderColor};
  font-size: ${fontSize.f3};
  ${media.xs`
    font-size: ${fontSize.f2};
  `}
`
