import { color, fontSize } from '@gtg/styles/theme'
import media from '@gtg/utils/media-queries'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Spinner from '../spinner'
import { displayBookingState } from './displayBookingState'

const StyledCalendar = styled.div`
  position: relative;
  margin: 2rem 0;
  min-height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${media.sm`
    margin: 1rem 0;
  `}
  ${media.xs`
    min-height: 250px;
  `}
`

const Table = styled.table`
  display: table;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  margin: 0;
  th,
  td {
    padding: 0;
    width: calc(100% / 7);
  }
  td {
    border: 1px solid ${color.grey300}!important;
  }
  ${media.xs`
    font-size: ${fontSize.f1};
  `}
`

const Cell = styled.td`
  text-align: center;
  box-shadow: ${props =>
    props.selected ? 'inset 0 0 0 2px ' + color.brand300 : 'none'};
  color: ${props => (props.current ? 'white' : 'initial')};
  background: ${props => (props.selected ? color.brand25 : 'none')};
  border-radius: 4px;
  cursor: pointer;
`

const BlankCell = styled.td`
  background: ${color.grey100};
`

const CellClickTarget = styled.button`
  touch-action: manipulation;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background: unset;
  color: unset;
  margin: 0;
  padding: 4px 0 2px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  :hover {
    background: unset;
  }
  ${media.xs`
    padding: 2px 0;
  `}
`

const CellLabel = styled.div`
  background: ${props => (props.current ? color.red500 : 'initial')};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  ${media.xs`
    width: 24px;
    height: 24px;
  `}
`

const XsShiftIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => props.color};
  border: 1px solid ${props => props.borderColor};
  margin-top: 0.5rem;
  display: none;
  margin-top: 0.25rem;
  ${media.xs`
    display: block;
  `}
`

const ShiftIndicatorWrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
  min-width: 10px;
  min-height: 17px;
`

const ShiftIndicator = styled.div`
  width: 8px;
  height: 8px;
  margin: 1px;
  border-radius: 50%;
  background: ${props => props.color};
  border: 1px solid ${props => props.borderColor};
  margin-top: 0.5rem;
  ${media.xs`
    display: none;
  `}
`

const StyledSpinner = styled(Spinner)`
  margin-right: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const SpinnerBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  min-width: 64px;
  min-height: 64px;
`

const WhiteOverlay = styled.div`
  position: absolute;
  min-width: calc(100% + 1px);
  min-height: 100%;
  background: rgba(255, 255, 255, 0.7);
`

const Calendar = props => {
  const [rowElements, setRowElements] = useState([])
  const dateObject = props.dateObject
  const shifts = props.shifts
  const selectedDayProp = props.selectedDay
  const setSelectedDay = props.setSelectedDay

  useEffect(() => {
    function daysInMonth() {
      return dateObject.daysInMonth()
    }

    function firstWeekdayOfMonth() {
      // corrected for week starting on monday in europe
      const day = moment(dateObject).startOf('month').format('d')
      if (day > 0) {
        return day - 1
      } else {
        return 6
      }
    }

    function currentDay() {
      return dateObject.format('D')
    }

    function shiftIndicators(day) {
      const shiftIndicatorElements = []
      let XsShiftIndicatorElement
      let contains1 = false
      let contains3 = false
      let contains4 = false
      let contains5 = false
      let contains6 = false
      let contains8 = false
      let contains100 = false
      let contains101 = false
      let contains99 = false
      let statusColor
      let borderColor
      let status
      if (shifts) {
        for (let i = 0; i < shifts.length; i++) {
          const shift = shifts[i]
          if (moment(shift.date).format('D') === day.toString()) {
            if (shift.capacity) {
              const capacity = shift.capacity.capacity
              const bookedCapacity = shift.capacity.bookedcapacity
              if (shift.booking && shift.type) {
                status = shift.booking.buchungstatus
                if (status === 1 && shift.type !== 2) {
                  // do not show special shift indicator if status is "requested"
                  contains1 = true
                } else if (status === 3 && shift.type !== 2) {
                  // do not show special shift indicator if status is "fixed"
                  // display fixed shifts as requested shifts
                  contains1 = true
                } else if (status === 4) {
                  contains4 = true
                } else if (status === 5 || status === 7) {
                  contains5 = true
                } else if (status === 6) {
                  contains6 = true
                } else if (status === 8) {
                  contains8 = true
                } else if (status === 10) {
                  if (capacity <= bookedCapacity) {
                    contains99 = true
                    status = 99
                  } else {
                    contains100 = true
                    status = 100
                  }
                } else if (
                  shift.booking.mindirectbooking !== null &&
                  status !== 7
                ) {
                  contains100 = true
                  status = 100
                } else {
                  contains101 = true
                }
              } else {
                if (capacity <= bookedCapacity) {
                  if (
                    shift.booking &&
                    shift.booking.mindirectbooking !== null &&
                    status !== 7
                  ) {
                    contains100 = true
                    status = 100
                  } else {
                    contains99 = true
                    status = 99
                  }
                } else {
                  contains100 = true
                  status = 100
                }
              }
              // If shift is special shift, only show shift indicator if its status is not requested and not fixed
              if (
                shift.type &&
                (shift.type !== 2 ||
                  (shift.type === 2 &&
                    shift.booking &&
                    shift.booking.buchungstatus &&
                    shift.booking.buchungstatus !== 3 &&
                    shift.booking.buchungstatus !== 1))
              ) {
                shiftIndicatorElements.push(
                  <ShiftIndicator
                    color={displayBookingState(status).statusColor}
                    borderColor={displayBookingState(status).statusBorderColor}
                    key={'miniShiftIndicator' + shift.date + shift.id + i}
                  />
                )
              }
            }
          }
        }
      }
      //logic which indicator color to choose for xs view
      statusColor = 'white'
      borderColor = 'white'
      if (contains101) {
        status = 101
      }
      if (contains99) {
        status = 99
      }
      if (contains4) {
        status = 4
      }
      if (contains100) {
        status = 100
      }
      if (contains6) {
        status = 6
      }
      if (contains5) {
        status = 5
      }
      if (contains8) {
        status = 8
      }
      if (contains3) {
        status = 3
      }
      if (contains1) {
        status = 1
      }
      statusColor = displayBookingState(status).statusColor
      borderColor = displayBookingState(status).statusBorderColor
      XsShiftIndicatorElement = (
        <XsShiftIndicator color={statusColor} borderColor={borderColor} />
      )
      return (
        <ShiftIndicatorWrapper key={'indicator' + day}>
          {shiftIndicatorElements.slice(0, 3)}
          {shifts && shifts.length > 0 && XsShiftIndicatorElement}
        </ShiftIndicatorWrapper>
      )
    }

    function calculateRowElements(selectedDay = selectedDayProp) {
      const startOfMonthBlankCells = []
      for (let i = 0; i < firstWeekdayOfMonth(); i++) {
        startOfMonthBlankCells.push(
          <BlankCell key={'blank' + i}>{''}</BlankCell>
        )
      }

      let daysOfMonthCells = []
      for (let dayIndex = 1; dayIndex <= daysInMonth(); dayIndex++) {
        const isCurrentDay =
          dayIndex.toString() === currentDay() &&
          moment().isSame(dateObject, 'month')
        daysOfMonthCells.push(
          <Cell
            key={'day' + dayIndex}
            selected={dayIndex === selectedDay}
            current={isCurrentDay}
          >
            <CellClickTarget onClick={e => setSelectedDay(dayIndex)}>
              <CellLabel current={isCurrentDay}>{dayIndex}</CellLabel>
              {shiftIndicators(dayIndex)}
            </CellClickTarget>
          </Cell>
        )
      }

      var totalCells = [...startOfMonthBlankCells, ...daysOfMonthCells]

      let rows = []
      let cellsInASingleRow = []

      totalCells.forEach((cell, i) => {
        if (i % 7 !== 0) {
          cellsInASingleRow.push(cell)
        } else {
          rows.push(cellsInASingleRow)
          cellsInASingleRow = []
          cellsInASingleRow.push(cell)
        }
        if (i === totalCells.length - 1) {
          rows.push(cellsInASingleRow)
        }
      })

      let rowElements = rows.map((d, i) => {
        return <tr key={'row' + i}>{d}</tr>
      })

      return rowElements
    }

    setRowElements(calculateRowElements())
  }, [shifts, dateObject, selectedDayProp, setSelectedDay])

  let weekDays = moment.weekdaysShort(true)

  return (
    <StyledCalendar>
      <Table>
        <thead>
          <tr>
            {weekDays.map(day => (
              <th key={day}>
                <CellLabel>{day}</CellLabel>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{rowElements}</tbody>
      </Table>
      {props.shiftsAreLoading && (
        <>
          <WhiteOverlay />
          <SpinnerBackground>
            <StyledSpinner
              color1={color.grey200}
              color2={color.grey400}
              size={32}
            />
          </SpinnerBackground>
        </>
      )}
    </StyledCalendar>
  )
}

export default Calendar
