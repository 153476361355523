import styled from 'styled-components'
import { color, fontSize } from '../../../styles/theme'

export const ShadowButton = styled.button`
  height: 38px;
  padding: 0 0.5rem;
  width: 100%;
  margin-bottom: 0;
  background: unset;
  font-size: ${fontSize.f2};
  color: ${color.grey400};
  font-weight: normal;
  text-decoration: underline;
  text-transform: initial;
  :hover {
    background: unset;
  }
  :active {
    background: unset;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`
