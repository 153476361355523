import Container from '@gtg/components/container'
import { color, fontSize } from '@gtg/styles/theme'
import media from '@gtg/utils/media-queries'
import moment from 'moment-timezone'
import 'moment/locale/de'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LogoSrc from '../../img/app/kvshlogo.jpg'
import GlobalWrapper from '../globalWrapper'
import { endpoint } from './auth'
import BookShifts from './bookShifts'
import ShiftPlan from './shiftPlan'
import VaccCenterInfo from './vaccCenterInfo'

moment.locale('de', {
  week: {
    dow: 1,
    doy: 4,
  },
})

moment.tz.setDefault('Europe/Berlin')

const Wrapper = styled.div`
  h1 {
    font-size: ${fontSize.f6};
    margin: 4rem 0 1rem 0;
    ${media.sm`
      margin: 2rem 0 1rem 0;
    `}
    ${media.xs`
      font-size: ${fontSize.f5};
    `}
  }
  select {
    max-width: 48rem;
    box-shadow: 0 0 0 1px ${color.grey300};
  }
  background: ${color.grey100};
  user-select: none;
`

const MessageWrapper = styled.div`
  background: white;
  padding: 0 1rem;
`

const ErrorMessage = styled.div`
  padding: 1rem;
  color: ${color.red500};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${color.red500};
  border-radius: 4px;
  background: ${color.red50};
  margin: 1rem 0 0 0;
`

const NotificationBanner = styled.div`
  padding: 1rem;
  color: ${color.brand500};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${color.brand500};
  border-radius: 4px;
  background: ${color.brand50};
  margin: 1rem 0 0 0;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${color.grey200};
  padding: 2rem 0 1.5rem 0;
  background: white;
`

const Logo = styled.div`
  background: url(${LogoSrc});
  width: 129px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

const VaccCenterSelectLabel = styled.label`
  font-size: ${fontSize.f4};
  ${media.sm`
    font-size: ${fontSize.f3};
  `}
`

const VaccCenterSelect = styled.div`
  padding: 0 1rem;
`

const tabsBorderRadius = '6px'
const tabsBorderWidth = '1px'
const tabsBorderStyle = `${tabsBorderWidth} solid #d6d6de`

const Tabbar = styled.div`
  padding: 0 1rem;
  border-bottom: ${tabsBorderStyle};
  display: flex;
  justify-content: center;
  ${media.xs`
    font-size: ${fontSize.f2};
  `}
`

const Tabs = styled.div`
  display: flex;
  max-width: 48rem;
  width: 100%;
`

const Tab = styled.div`
  text-align: center;
  width: 50%;
  padding: 0.5rem;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: ${tabsBorderStyle};
  border-left: ${tabsBorderStyle};
  border-right: ${tabsBorderStyle};
  border-bottom: ${props =>
    props.selected ? tabsBorderWidth + ' solid white' : tabsBorderStyle};
  transform: ${props =>
    props.rightTab
      ? `translate(-${tabsBorderWidth}, ${tabsBorderWidth})`
      : `translateY(${tabsBorderWidth})`};
  border-top-left-radius: ${tabsBorderRadius};
  border-top-right-radius: ${tabsBorderRadius};
  font-weight: 700;
  color: ${props => (props.selected ? color.grey900 : color.grey500)};
  background: ${props => (props.selected ? 'white' : 'unset')};
  cursor: pointer;
`

const WhiteBackground = styled.div`
  background: white;
  padding: 0 1rem 3rem 1rem;
`

const Schedule = props => {
  const [selectedTab, setSelectedTab] = useState(1)
  const [vaccCenters, setVaccCenters] = useState()
  const [selectedVaccCenterId, setSelectedVaccCenterId] = useState()
  const [dateObject, setDateObject] = useState(moment())
  const [selectedDay, setSelectedDay] = useState(1)
  const [shifts, setShifts] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [shiftsAreLoading, setShiftsAreLoading] = useState(false)
  const [currentMonthStr, setCurrentMonthStr] = useState(
    moment().format('MMMM YYYY')
  )
  const notificationMessage = ''

  useEffect(() => {
    fetchVaccCenters()
  }, [])

  function fetchVaccCenters() {
    fetch(endpoint + '/vacc_center/', {
      headers: {
        Authorization: `Bearer ${props.accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Dienst zur Zeit nicht verfügbar')
        }
      })
      .then(response => {
        if (response.length !== 0) {
          setVaccCenters(response)
          const selectedVaccCenterId = response[0].id
          setSelectedVaccCenterId(selectedVaccCenterId)
          fetchShifts(selectedVaccCenterId)
        } else {
          throw new Error('Ihnen wurde bisher kein Impfzentrum zugeordnet')
        }
      })
      .catch(error => {
        setErrorMessage(error.message)
      })
  }

  function fetchShifts(selectedVaccCenterId) {
    setShiftsAreLoading(true)
    if (selectedVaccCenterId) {
      fetch(
        endpoint +
          `/shift/?date_from=${shiftQueryFromDate()}&date_to=${shiftQueryToDate()}&vaccination_center=${selectedVaccCenterId}`,
        {
          headers: {
            Authorization: `Bearer ${props.accessToken}`,
          },
        }
      )
        .then(response => {
          if (response.status === 200) {
            return response.json()
          } else {
            throw new Error('Dienst zur Zeit nicht verfügbar')
          }
        })
        .then(response => {
          setShifts(response.sort(sortByDateAndTime))
          setShiftsAreLoading(false)
        })
        .catch(error => {
          setErrorMessage(error.message)
          setShiftsAreLoading(false)
        })
    }
  }

  function sortByDateAndTime(shiftA, shiftB) {
    if (moment(shiftA.startzeitdatum).isBefore(moment(shiftB.startzeitdatum))) {
      return -1
    } else {
      return 1
    }
  }

  function shiftQueryFromDate() {
    const shiftQueryFromDate = `${dateObject.format('YYYY-MM')}-1`
    return shiftQueryFromDate
  }

  function shiftQueryToDate() {
    const shiftQueryToDate = `${dateObject.format(
      'YYYY-MM'
    )}-${dateObject.daysInMonth()}`
    return shiftQueryToDate
  }

  function onPrev() {
    setSelectedDay(1)
    setDateObject(dateObject.subtract(1, 'month'))
    setCurrentMonthStr(dateObject.format('MMMM YYYY'))
    fetchShifts(selectedVaccCenterId)
  }

  function onNext() {
    setSelectedDay(1)
    setDateObject(dateObject.add(1, 'month'))
    setCurrentMonthStr(dateObject.format('MMMM YYYY'))
    fetchShifts(selectedVaccCenterId)
  }

  function handleVaccCenterSelect(e) {
    setSelectedVaccCenterId(e.target.value)
    fetchShifts(e.target.value)
  }

  return (
    <GlobalWrapper>
      <Wrapper>
        <MessageWrapper>
          <Container background="white">
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </Container>
          {notificationMessage && (
            <Container background="white">
              <NotificationBanner>{notificationMessage}</NotificationBanner>
            </Container>
          )}
        </MessageWrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Container>
          <VaccCenterSelect>
            <VaccCenterSelectLabel>Impfzentrum</VaccCenterSelectLabel>
            <select
              value={selectedVaccCenterId}
              onChange={handleVaccCenterSelect}
            >
              {!vaccCenters && (
                <option disabled value>
                  Lädt...
                </option>
              )}
              {vaccCenters &&
                vaccCenters.map((vaccCenter, i) => (
                  <option key={'vaccCenter' + i} value={vaccCenter.id}>
                    {vaccCenter.name}
                  </option>
                ))}
            </select>
          </VaccCenterSelect>
          <VaccCenterInfo
            selectedVaccCenterId={selectedVaccCenterId}
            accessToken={props.accessToken}
          />
        </Container>
        <Tabbar>
          <Tabs>
            <Tab selected={selectedTab === 1} onClick={() => setSelectedTab(1)}>
              Schichten wählen
            </Tab>
            <Tab
              selected={selectedTab === 2}
              onClick={() => setSelectedTab(2)}
              rightTab
            >
              Ihr Schichtplan
            </Tab>
          </Tabs>
        </Tabbar>
        <WhiteBackground>
          <Container>
            {selectedTab === 1 && (
              <BookShifts
                dateObject={dateObject}
                onPrev={onPrev}
                onNext={onNext}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                shifts={shifts}
                selectedVaccCenterId={selectedVaccCenterId}
                fetchShifts={fetchShifts}
                accessToken={props.accessToken}
                shiftsAreLoading={shiftsAreLoading}
                currentMonthStr={currentMonthStr}
              />
            )}
            {selectedTab === 2 && (
              <ShiftPlan
                dateObject={dateObject}
                onPrev={onPrev}
                onNext={onNext}
                selectedDay={selectedDay}
                shifts={shifts}
                selectedVaccCenterId={selectedVaccCenterId}
                fetchShifts={fetchShifts}
                accessToken={props.accessToken}
                shiftsAreLoading={shiftsAreLoading}
                currentMonthStr={currentMonthStr}
              />
            )}
          </Container>
        </WhiteBackground>
      </Wrapper>
    </GlobalWrapper>
  )
}

export default Schedule
