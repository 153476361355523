import moment from 'moment-timezone'
import React from 'react'
import AvailableShifts from './availableShifts'
import MonthSelect from './monthSelect'
import styled from 'styled-components'

const ExplainText = styled.div`
  margin-bottom: 1rem;
`

const shiftPlan = props => {
  function shiftIsBooked(shift) {
    if (
      shift.booking &&
      shift.booking.originbookingid !== null &&
      moment(shift.date).isSame(props.dateObject, 'month')
    ) {
      return shift
    }
  }
  return (
    <>
      <h1>Ihr Schichtplan</h1>
      <ExplainText>
        Übersicht über Ihre angefragten und gebuchten&nbsp;Schichten
      </ExplainText>
      <MonthSelect
        onPrev={props.onPrev}
        onNext={props.onNext}
        dateObject={props.dateObject}
        currentMonthStr={props.currentMonthStr}
      />
      <AvailableShifts
        dateObject={props.dateObject}
        selectedDay={props.selectedDay}
        shifts={props.shifts && props.shifts.filter(shiftIsBooked)}
        selectedVaccCenterId={props.selectedVaccCenterId}
        fetchShifts={props.fetchShifts}
        accessToken={props.accessToken}
        shiftsAreLoading={props.shiftsAreLoading}
        shiftPlan
      />
    </>
  )
}

export default shiftPlan
