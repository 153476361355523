import { color, fontSize } from '@gtg/styles/theme'
import media from '@gtg/utils/media-queries'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { auth } from './auth'
import Spinner from '../spinner'

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.grey100};
`

const Card = styled.div`
  border: 1px solid ${color.grey300};
  padding: 2rem;
  box-shadow: 0px 5px 15px ${color.grey200};
  border-radius: 8px;
  background: white;
  text-align: center;
  h1 {
    margin-bottom: 2rem;
    font-size: ${fontSize.f5};
    ${media.xs`
      font-size: ${fontSize.f4};
    `}
  }
  border: ${props =>
    props.hasBeenValidated
      ? '2px solid ' + color.red600
      : '1px solid' + color.grey300};
  background: ${props => (props.hasBeenValidated ? color.red50 : 'white')};
  margin: 0.5rem;
  ${media.xs`
    padding: 1rem;
    box-shadow: none;
    width: 100%;
  `}
`

const InputFieldWrapper = styled.div`
  font-size: ${fontSize.f7};
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.xs`
    font-size: ${fontSize.f5};
  `}
`

const Separator = styled.div`
  margin: 0 0.5rem;
  display: inline;
  ${media.xs`
    margin: 0 0.25rem;
  `}
`

const InputField = styled.input`
  padding: 4rem;
  margin: 0;
  font-size: ${fontSize.f7}!important;
  padding: 0.5rem !important;
  max-width: 72px;
  ::placeholder {
    color: ${color.grey200};
  }
  ${media.xs`
    font-size: ${fontSize.f5}!important;
    max-width: 54px;
  `}
`

const ErrorMessageWrapper = styled.div`
  padding: 1rem;
  max-width: 450px;
`

const ErrorMessage = styled.div`
  padding: 1rem;
  color: ${color.red500};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${color.red500};
  border-radius: 4px;
  background: ${color.red50};
`

const TanButton = styled.button`
  width: 100%;
  height: 60px;
  background: ${props =>
    getButtonBackground(props.isInputComplete, props.isValidating)};
  :hover {
    background: ${props =>
      getButtonHoverBackground(props.isInputComplete, props.isValidating)};
  }
  cursor: ${props => (props.isInputComplete ? 'pointer' : 'not-allowed')};
  :focus {
    box-shadow: ${props =>
      props.isInputComplete ? '0px 0px 0px 2px' + color.brand800 : 'none'};
  }
`

const StyledSpinner = styled(Spinner)`
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`

const getButtonBackground = (isInputComplete, isValidating) => {
  if (isValidating === true) {
    return color.brand400
  } else {
    if (isInputComplete === true) {
      return color.brand500
    } else {
      return color.grey300
    }
  }
}

const getButtonHoverBackground = (isInputComplete, isValidating) => {
  if (isValidating === true) {
    return color.brand400
  } else {
    if (isInputComplete === true) {
      return color.brand400
    } else {
      return color.grey300
    }
  }
}

const Tan = props => {
  const [isInputComplete, setIsInputComplete] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [hasBeenValidated, setHasBeenValidated] = useState(false)
  const [input1, setInput1] = useState('')
  const [input2, setInput2] = useState('')
  const [input3, setInput3] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const userId = props.userId
  const inputField1 = useRef(null)
  const inputField2 = useRef(null)
  const inputField3 = useRef(null)
  const submitButton = useRef(null)
  const maintenanceWindowActive = false
  const maintenanceMessage =
    'Der Schichtplan steht aufgrund von Wartungsarbeiten bis voraussichtlich 20 Uhr nicht zur Verfügung.'

  useEffect(() => {
    inputField1.current.focus()
    inputField1.current.select()
    if (typeof window !== `undefined` && window.lifetimeAppInterface) {
      fillInputsWithTan(window.lifetimeAppInterface.getTan())
    }
  }, [])

  useEffect(() => {
    checkIfInputComplete()
  })

  function checkIfInputComplete() {
    if ((input1 + input2 + input3).length === 9) {
      setIsInputComplete(true)
    } else {
      setIsInputComplete(false)
    }
  }

  const isNumeric = new RegExp('^[0-9]+$')

  const validateInput = (input, setInput, nextFocusElement) => {
    if ((input.length < 4 && isNumeric.test(input) === true) || input === '') {
      setInput(input)
      if (input.length === 3) {
        nextFocusElement.current.focus()
        if (typeof nextFocusElement.current.select == 'function') {
          nextFocusElement.current.select()
        }
      }
    } else {
      return
    }
  }

  async function validateTan() {
    const tan = input1 + input2 + input3
    if (tan.length === 9) {
      setIsValidating(true)
      const { isValid, accessToken, errorMessage } = await auth(userId, tan)
      setIsValidating(false)
      setErrorMessage(errorMessage)
      setHasBeenValidated(true)
      props.setTanIsValid(isValid)
      props.setAccessToken(accessToken)
      if (isValid) {
        props.navigate('/app/kvsh/schedule/' + userId)
      } else {
        return
      }
    }
  }

  const pasteTan = e => {
    const clipBoardText = e.clipboardData.getData('text/plain')
    fillInputsWithTan(clipBoardText)
  }

  const fillInputsWithTan = stringTan => {
    const numericTan = stringTan.replace(/\D/g, '')
    const tan1 = numericTan.slice(0, 3)
    const tan2 = numericTan.slice(3, 6)
    const tan3 = numericTan.slice(6, 9)
    setInput1(tan1)
    setInput2(tan2)
    setInput3(tan3)
    submitButton.current.focus()
  }

  return (
    <Background>
      <Card hasBeenValidated={hasBeenValidated}>
        <h1>TAN eingeben:</h1>
        <InputFieldWrapper>
          <InputField
            id="inputField1"
            placeholder="_ _ _"
            onChange={e =>
              validateInput(e.target.value, setInput1, inputField2)
            }
            value={input1}
            maxLength="3"
            onPaste={e => pasteTan(e)}
            type="tel"
            ref={inputField1}
          />
          <Separator>–</Separator>
          <InputField
            id="inputField2"
            placeholder="_ _ _"
            onChange={e =>
              validateInput(e.target.value, setInput2, inputField3)
            }
            value={input2}
            maxLength="3"
            onPaste={e => pasteTan(e)}
            type="tel"
            ref={inputField2}
          />
          <Separator>–</Separator>
          <InputField
            id="inputField3"
            placeholder="_ _ _"
            onChange={e =>
              validateInput(e.target.value, setInput3, submitButton)
            }
            value={input3}
            maxLength="3"
            onPaste={e => pasteTan(e)}
            type="tel"
            ref={inputField3}
          />
        </InputFieldWrapper>
        {maintenanceWindowActive ? (
          <>
            <ErrorMessageWrapper>
              <ErrorMessage>{maintenanceMessage}</ErrorMessage>
            </ErrorMessageWrapper>
            <TanButton
              isValidating={isValidating}
              tabIndex={isInputComplete ? '0' : '-1'}
              isInputComplete={false}
              ref={submitButton}
            >
              Seite öffnen
            </TanButton>
          </>
        ) : (
          <>
            <ErrorMessageWrapper>
              {hasBeenValidated && !props.tanIsValid && (
                <ErrorMessage>{errorMessage}</ErrorMessage>
              )}
            </ErrorMessageWrapper>
            <TanButton
              isValidating={isValidating}
              tabIndex={isInputComplete ? '0' : '-1'}
              isInputComplete={isInputComplete}
              onClick={() => validateTan()}
              ref={submitButton}
            >
              {isValidating && <StyledSpinner />}
              {!isValidating && 'Seite öffnen'}
            </TanButton>
          </>
        )}
      </Card>
    </Background>
  )
}

export default Tan
