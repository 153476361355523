import { color, fontSize } from '@gtg/styles/theme'
import media from '@gtg/utils/media-queries'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { endpoint } from './auth'

const VaccCenterInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 3rem 0;
  line-height: 1.4;
  padding: 0 1rem;
  ${media.sm`
    margin: 0.25rem 0 1.25rem 0;
  `}
`

const VaccCenterIcon = styled.div`
  border-radius: 50%;
  background: ${color.grey200};
  width: 72px;
  height: 72px;
  color: ${color.grey300};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: ${fontSize.f8};
  text-transform: uppercase;
  margin-right: 1.5rem;
  flex-shrink: 0;
  ${media.sm`
    margin-right: 1rem;
    width: 52px;
    height: 52px;
    font-size: ${fontSize.f7};
  `}
`

const VaccCenterInfos = styled.div``

const VaccCenterName = styled.div`
  font-weight: 900;
  font-size: ${fontSize.f6};
  ${media.sm`
    font-size: ${fontSize.f3};
    font-weight: 700;
  `}
`
const VaccCenterAddress = styled.div`
  color: ${color.grey700};
  font-size: ${fontSize.f3};
  ${media.sm`
    font-size: ${fontSize.f2};
  `}
`

const VaccCenterInfo = props => {
  const [vaccCenter, setVaccCenter] = useState()

  useEffect(() => {
    function fetchVaccCenterInfo() {
      fetch(endpoint + `/vacc_center/${props.selectedVaccCenterId}/`, {
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            return response.json()
          } else {
            throw new Error('Keine Informationen verfügbar')
          }
        })
        .then(response => {
          setVaccCenter(response)
        })
        .catch(error => {
          console.log(error.message)
        })
    }

    if (props.selectedVaccCenterId) {
      fetchVaccCenterInfo()
    }
  }, [props.selectedVaccCenterId, props.accessToken])

  return (
    <VaccCenterInfoWrapper>
      <VaccCenterIcon>
        {vaccCenter && vaccCenter.name.slice(0, 1)}
      </VaccCenterIcon>
      {vaccCenter && (
        <VaccCenterInfos>
          <VaccCenterName>{vaccCenter.name}</VaccCenterName>
          <VaccCenterAddress>
            {vaccCenter.address} / {vaccCenter.zipcode} {vaccCenter.city}
          </VaccCenterAddress>
        </VaccCenterInfos>
      )}
    </VaccCenterInfoWrapper>
  )
}

export default VaccCenterInfo
