import { color, fontSize } from '@gtg/styles/theme'
import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'
import AvailableShifts from './availableShifts'
import Calendar from './calendar'
import MonthSelect from './monthSelect'

const AvailableShiftsTitle = styled.div`
  font-weight: 900;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${color.grey200};
  text-align: center;
  font-size: ${fontSize.f5};
`

const ExplainText = styled.div`
  margin-bottom: 1rem;
`

const BookShifts = props => {
  function shiftIsOnSelectedDay(shift) {
    if (
      moment(shift.date).format('D') === props.selectedDay.toString() &&
      moment(shift.date).isSame(props.dateObject, 'month')
    ) {
      return shift
    }
  }
  return (
    <>
      <h1>Schichten wählen</h1>
      <ExplainText>
        Klicken Sie auf ein Datum und scrollen Sie nach unten, um Schichten zu
        wählen.
      </ExplainText>
      <MonthSelect
        onPrev={props.onPrev}
        onNext={props.onNext}
        currentMonthStr={props.currentMonthStr}
      />
      <Calendar
        dateObject={props.dateObject}
        onPrev={props.onPrev}
        onNext={props.onNext}
        setSelectedDay={props.setSelectedDay}
        selectedDay={props.selectedDay}
        shifts={props.shifts}
        shiftsAreLoading={props.shiftsAreLoading}
      />
      <AvailableShiftsTitle>
        {props.selectedDay}. {props.currentMonthStr}
      </AvailableShiftsTitle>
      <AvailableShifts
        dateObject={props.dateObject}
        selectedDay={props.selectedDay}
        shifts={props.shifts && props.shifts.filter(shiftIsOnSelectedDay)}
        selectedVaccCenterId={props.selectedVaccCenterId}
        fetchShifts={props.fetchShifts}
        accessToken={props.accessToken}
        shiftsAreLoading={props.shiftsAreLoading}
      />
    </>
  )
}

export default BookShifts
