import styled from 'styled-components'
import Spinner from '../../spinner'

export const StyledSpinner = styled(Spinner)`
  margin-right: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
