import styled from 'styled-components'
import { color, fontSize } from '../../../styles/theme'

export const ErrorMessage = styled.div`
  height: 38px;
  padding: 0 0.5rem;
  color: ${color.red500};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: ${fontSize.f2};
`
