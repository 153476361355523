import React, { useState } from 'react'
import { endpoint } from '../auth'
import { StyledSpinner } from './styledSpinner'
import { ShadowButton } from './shadowButton'
import { ErrorMessage } from './errorMessage'
import { color } from '../../../styles/theme'

export const CancelLookForSubstituteButton = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  async function removeSubstituteRequest(shift) {
    setIsLoading(true)
    await fetch(
      `${endpoint}/shift/booking/${shift.booking.originbookingid}/remove-substitute/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      }
    )
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Vertretungssuche abbrechen fehlgeschlagen')
        }
      })
      .then(_ => {
        props.fetchShifts(props.selectedVaccCenterId)
      })
      .catch(error => {
        setErrorMessage(error.message)
        setIsLoading(false)
      })
  }
  return (
    <>
      <ShadowButton onClick={() => removeSubstituteRequest(props.shift)}>
        {isLoading && (
          <StyledSpinner color1={color.grey100} color2={color.grey300} />
        )}
        {!isLoading && props.children}
      </ShadowButton>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
}
